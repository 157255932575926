<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems.sync="tabItems"
      v-model="tab"
      align="left"
      :height.sync="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param.sync="param"
          :height="height"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar';
export default {
  name: 'project-attend-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
    height: {
      type: [String, Number],
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: { data: 180 },
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '참여업체', icon: 'how_to_reg', component: () => import(`${'./projectAttendVendor.vue'}`), key: uid(),  },
        { name: 'tab2', label: '시공사인력', icon: 'real_estate_agent', component: () => import(`${'./projectAttendSelf.vue'}`),key: uid(), },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = window.innerHeight;
      numHeight = numHeight - 120
      return numHeight + 'px';
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>